

.useful-links-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @media(min-width: 900px) {
    flex-direction: row;
  }
}