.community {
  display: grid;
  padding: 20px;
}

.community > img {
  width: 100%;
}

@media(min-width: 900px) {
  .community > img {
    padding: 1rem;
  }
}

@media (min-width: 900px) {

  .community {
    padding: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

.communityContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}

.communityContent > h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0;
  color: var(--blue);
}

.communityContent > p {
  font-size: 1.5rem;
  font-weight: 400;
}