.location {
  background: var(--yellow);
  padding: 20px;
  display: grid;
}

.locationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.locationContent > .locationIcon {
  font-size: 5rem;
  color: var(--blue);
  padding: 1rem;
}

.locationContent > div > p {
  font-size: 2rem;
  color: var(--blue);
  font-weight: bold;
  margin-bottom: 0;
}

.locationContent > div > ul {
  list-style: none;
  padding-left: 0;
}

.locationContent > div > ul > li {
  font-size: 1.5rem;
  color: var(--black);
}

.locationMap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationMap > iframe {
  border: none;
  border-radius: 15px;
  padding: 1rem;
  width: 100%;
  max-width: 400px;
}

@media(min-width: 900px) {
  .location {
    grid-template-columns: repeat(2, 1fr);
  }

  .locationContent {
    flex-direction: row;
  }
}

.donate-button > .paypalIcon {
  padding-right: 10px;
}