@font-face {
  font-family: 'helveticaneue_lt_35_thinRg';
  src: url('./fonts/helveticaneue_lt_35_thin_regular-webfont.woff2') format('woff2'),
  url('./fonts/helveticaneue_lt_35_thin_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}