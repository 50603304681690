.btn {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  cursor: pointer;
  display: flex;
  margin: 0;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 5px 23px 5px;
  border: none;
  min-height: 50px;
  line-height: 20px;
  letter-spacing: 0;
  white-space: normal;
  background-color: var(--yellow);
  color: #000;
  align-items: center;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  align-self: flex-start;
  justify-content: center;
}

.btn:hover {
  border-radius: 15px;
}

@media (min-width: 768px) {
  .btn {
    font-size: 19px;
  }
}

/*Blue Button*/
.btn--Blue {
  background-color: var(--blue);
  color: var(--white);
}

.btn--Blue:hover {
  background-color: var(--sec-darkBlue);
}

/*Yellow Button*/
.btn--Yellow {
  background-color: var(--yellow);
  color: var(--blue);
}

.btn--Yellow:hover {
  background-color: var(--blue);
  color: var(--white);
}