.theHall {
  display: grid;
  gap: 1rem;
  padding: 20px;
}

@media(min-width: 900px) {
  .theHall {
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }
}

.theHall > img {
  width: 100%;
  height: auto;
  align-self: center;
}

@media(min-width: 900px) {
  .theHall > img {
    padding: 1rem;
  }
}

.theHallContent {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.theHallContent > h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 0;
  color: var(--blue);
}

.theHallContent > p {
  font-size: 1.5rem;
  font-weight: 400;
}

.theHallContent > .buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media(min-width: 500px) {
    flex-direction: row;
  }
}

.learnMore {
  margin-left: 1rem;
}

.payPal {
  height: 100%;
}