.youthAndDisaster {
  display: grid;
  padding: 20px;
  justify-content: center;
  gap: 1rem;
  grid-template-areas:
  "youth"
  "disaster"
  "meeting";
}

@media(min-width: 700px) {
  .youthAndDisaster {
    justify-content: space-between;
    grid-template-areas: "youth disaster"
    "meeting meeting";
    padding: 2rem;
  }
}

@media(min-width: 1060px) {
  .youthAndDisaster {
    justify-content: space-between;
    grid-template-areas: "youth meeting disaster";
    padding: 2rem;
  }
}

.infoCol {
  padding: 1rem;
  max-width: 400px;
}

.infoCol > img {
  width: 100%;
  height: auto;
  align-self: center;
}

.infoCol > h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: var(--blue);
}

.infoCol > p {
  font-size: 1.5rem;
  font-weight: 400;
}

.youth {
  grid-area: youth;
}

.disaster {
  grid-area: disaster;
}

.meeting {
  grid-area: meeting;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meetingContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.meetingContent > p {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}

.meetingIcon {
  font-size: 5rem;
  color: var(--yellow);
}

.meetingContent > h3 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  margin-top: 1rem;
  color: var(--blue);
  text-align: center;
}

.meetingContent > img {
  width: 100%;
  height: auto;
  align-self: center;
  max-width: 150px;
}