.upperHeader {
  align-items: center;
  border-bottom: 2px solid var(--blue);
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.upperHeader > .btn > span {
  display: none;
}

.logo {
  height: 40px;
  transition: all 0.3s ease-in-out;
}

/*Medium Breakpoint*/
@media (min-width: 768px) {

  .logo {
    height: 80px;
  }

  header > h1 {
    display: block;
  }

  .phoneIcon {
    margin-right: 0.5rem;
  }

  .upperHeader > .btn > span {
    display: block;
  }
}

.lowerHeader {
  background: var(--blue);
  color: var(--white);
  display: flex;
  justify-content: space-between;
  padding: 5px 20px;
}

.lowerHeader > h1 {
  font-size: 1rem;
}

.lowerHeader > p {
  font-size: 0.8rem;
}

.lowerHeader > h1 > span {
  display: none;
}

.right-nav-links {
  text-align: center;
}

.space-bottom {
  margin-bottom: 10px;
}

.e-clubhouse {
  text-decoration: none;
  color: var(--blue)
}

.e-clubhouse:hover {
  text-decoration: underline;
}

.hide-mobile {
  display: none;
}

@media (min-width: 764px) {
  .hide-mobile {
    display: inline-block;
  }
}