.hero {
  background-repeat: no-repeat;
  background-position: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}

.heroMobile {
  display: block;
}

.hero-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  max-width: 800px;
}

.hero-content > .quote {
  color: var(--sec-darkGray);
  font-style: italic;
  font-weight: 400;
}

.hero-content > .tagline {
  color: var(--blue);
  font-family: "Times New Roman", Times, serif;
  font-size: 2.3rem;
  font-style: italic;
  font-weight: 600;
  text-align: center;
}

@media(min-width: 800px) {
  .hero {
    background-image: url('../media/images/hero-bg.png');
  }

  .heroMobile {
    display: none;
  }

  .hero-content {
    margin-left: 40vw;
  }

  .hero-content > .tagline {
    text-align: left;
  }
}
