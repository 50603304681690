:root {
  --blue: #00338D;
  --yellow: #EBB700;
  --purple: #7A2582;
  --white: #fff;
  --black: #000;

  --sec-lightBlue: #407CCA;
  --sec-darkBlue: #0D2240;
  --sec-lightGray: #b3b2b1;
  --sec-darkGray: #55565A;
  --sec-green: #00AB68;
  --sec-red: #ff5b35;
}